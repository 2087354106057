export const BaseURL = "https://server.ecubesap.co.in"


// Pst Method
export const login = '/admin_auth/login/'
export const logoutApi = '/admin_auth/logout/'
export const resetPassword = "/admin_auth/forgot/password/"

// Batch
export const AddABatch = '/register/admin/add/class/details/'
export const GetAllBatches = '/register/admin/get/class/details/'
export const GetABatch = '/register/admin/student/class/get/all/'  //=> ?batch_year=2024&class_name=PLUS ONE&division=A
export const UpdateABatch = '/register/admin/update/class/details/'
export const DeleteABatch = '/register/admin/delete/class/details/' //=> ?id


// Add Student
export const AddStudentData = "/register/admin/student/individual/" //=> ?batch_year=2023&class_name=CLASS X&division=D&subjects=PHYSICS
export const NextAdmissionNumber = "/register/admin/get/next/admission-no/"
export const DeleteStudentData = "/register/admin/student/delete/ind/"
export const updateStudentData = "/register/admin/student/individual/" //=>id
export const AttendanceStudentData = "/student_attendance/admin/attendance/get/status/" //=>?user_id=132
export const AttendanceStudentDate = "/student_attendance/admin/attendance/get/status/report/" //=>?user_id=132
export const AttendanceStudentReport = "/student_attendance/admin/attendance/get/report/" //=>?user_id=50&month_year_number=02/2024
export const AttendanceStudent = "/student_attendance/admin/attendance/get/" //=>?user_id=50&month_year_number=02/2024
export const ExamResultStudentData = "/student_exam_result/get/admin/exam-result/" //=>?user_id=132
export const DailyUpdateStudentData = "/student_daily_activities/admin/get/daily-activities/" //=>?user_id=132&date=24/08/2023
export const blockStudentData = "/register/admin/set/restricted/" //=>?user_id=132&date=24/08/2023

// Daily Updates
export const DailyActivities = "/student_daily_activities/daily-activities/add/bulk/" //=> ?batch_year=2024&class_name=PLUS ONE&division=A
export const GetDailyActivities = "/student_daily_activities/admin/get/all/" //=> ?batch_year=2024&class_name=PLUS ONE&division=A


// Get a single Student
export const GetASingleStudent = "/register/admin/student/get/ind/"  //=> id


// filter End Points
export const year = "/class_management/filter/batch_years/" //=>
export const classNa = "/class_management/filter/class_names/" //=>
export const division = "/class_management/filter/divisions/" //=>
export const dateSelect = "/student_attendance/admin/attendance/get/status/" //=>?batch_year=2024&class_name=TEST BATCH&division=A


// Attendance
export const AddAttendance = "/student_attendance/attendance/add/bulk/" //=>?batch_year=2024&class_name=PLUS ONE&division=A&date=1/2/2024
export const GetAttendance = "/student_attendance/admin/attendance/get/" //=>?batch_year=2024&class_name=PLUS ONE&division=A&date=1/2/2024
export const DeleteBulkAttendance = "/student_attendance/attendance/delete/bulk" //=>?batch_year=2024&class_name=TEST BATCH&division=A&date=09/10/2023


// Exam Result
export const AddExamResult = "/student_exam_result/exam-result/add/"
export const getExamResult = "/student_exam_result/exam-result/get/"
export const getExam = "/student_exam_result/exam-name/filter/"

// Daily Link
export const DailyLink = "/class/updates/create/link/"
export const GetLink = "/class/updates/get/link/"        //=>"?class_name=PLUS one&batch_year=2024&division=a"
export const CreateClassLink = "/class/updates/create/link/"        //=>"?_id"
export const UpdateClassLink = "/class/updates/update/link/"        //=>"?link_id"
export const DeleteClassLink = "/class/updates/update/link/"        //=>"?link_id"

// video Link
export const RecordedVideo = "/class/updates/recordings/operation/"
export const GetVideo = "/class/updates/recordings/operation/"
export const DeleteVideo = "/class/updates/recordings/operation/" //?recordings_id=1
export const UpdateVideo = "/class/updates/recordings/operation/" 


// Announcements 
export const GetAllAnnouncement = "/class/updates/announcement/operation/"
export const CreateAnnouncementData = "/class/updates/announcement/operation/"
export const UpdateAnnouncementData = "/class/updates/announcement/operation/"
export const DeleteAnnouncementData = "/class/updates/announcement/operation/"


// Leader Board 
export const GetLeaderBoard = "/student_leaderboard/admin/get_leaderboard/" //?subject=MATHS
export const GetExam = "/student_leaderboard/admin/get/exams" //?subject=MATHS